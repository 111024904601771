var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container px-0",
      style: { minHeight: _vm.minHeight + "px" },
    },
    [
      _c(
        "div",
        { class: { center: _vm.centerXy, verticalCenter: !_vm.centerXy } },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }