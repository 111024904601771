var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-screen flex w-full bg-img" }, [
    _c(
      "div",
      {
        staticClass:
          "vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center",
      },
      [
        _c("vx-card", [
          _c(
            "div",
            {
              staticClass: "full-page-bg-color",
              attrs: { slot: "no-body" },
              slot: "no-body",
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center",
                  },
                  [
                    _c("img", {
                      staticClass: "mx-auto",
                      attrs: {
                        src: require("@/assets/images/pages/forgot-password.png"),
                        alt: "login",
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg",
                  },
                  [
                    _vm.recoverDone
                      ? _c(
                          "div",
                          { staticClass: "p-8" },
                          [
                            _c("div", { staticClass: "vx-card__title mb-8" }, [
                              _c("h4", { staticClass: "mb-4" }, [
                                _vm._v("Email Sent!"),
                              ]),
                            ]),
                            _c(
                              "tp-cardtext",
                              { attrs: { "min-height": 150 } },
                              [
                                _c("p", [
                                  _vm._v(
                                    "If the username / email address you have entered is registered to an account, you will receive an email with instructions to reset your password."
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "vs-button",
                              {
                                staticClass:
                                  "float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-6",
                                attrs: { to: "/login" },
                              },
                              [_vm._v("Login Page\n                          ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.recoverDone
                      ? _c(
                          "div",
                          { staticClass: "p-8" },
                          [
                            _c("div", { staticClass: "vx-card__title mb-8" }, [
                              _c("h4", { staticClass: "mb-4" }, [
                                _vm._v("Recover your password"),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "Please enter your email address and we'll send you instructions on how to reset your password."
                                ),
                              ]),
                            ]),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|email",
                                  expression: "'required|email'",
                                },
                              ],
                              staticClass: "w-full",
                              attrs: {
                                type: "email",
                                "data-vv-validate-on": "blur",
                                "label-placeholder": "Email",
                                name: "email",
                              },
                              model: {
                                value: _vm.email,
                                callback: function ($$v) {
                                  _vm.email = $$v
                                },
                                expression: "email",
                              },
                            }),
                            _c("span", { staticClass: "text-danger text-sm" }, [
                              _vm._v(_vm._s(_vm.errors.first("email"))),
                            ]),
                            _c("div", { staticClass: "w-full mb-8" }),
                            _c(
                              "vs-button",
                              {
                                staticClass: "px-4 w-full md:w-auto",
                                attrs: { type: "border", to: "/login" },
                              },
                              [_vm._v("Back To Login")]
                            ),
                            _c(
                              "vs-button",
                              {
                                staticClass:
                                  "float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0",
                                attrs: { disabled: !_vm.validateForm },
                                on: { click: _vm.recoverPassword },
                              },
                              [
                                _vm._v(
                                  "Recover Password\n                            "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }